<template>
  <div class="grow">
    <v-data-table
      :headers="tabelaHeader"
      :items="todosAnexos"
      :items-per-page="100"
      hide-default-footer
      mobile-breakpoint="300"
    >
      <template v-slot:top>
        <p class="text-h6 font-weight-bold pt-0 mb-0 ml-4 mt-4">
          {{ multiplo ? `Anexos de ${definirNomePorTipoRegua}` : "Anexos" }}
        </p>
      </template>
      <template v-slot:body="{ items, attrs, on }">
        {{ attrs }}
        <tbody
          v-if="!carregandoAnexos && !erroCarregarAnexos"
          v-on="on"
          v-bind="attrs"
        >
          <tr
            v-for="item in items"
            :key="item.id"
            @mouseover="selecionaItem(item)"
            @mouseleave="desselecionarItem()"
          >
            <td class="text-left">
              <template>
                <v-tooltip bottom v-if="item.nome_arquivo.length > maxLengths">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="limite-caracteres">
                      {{ limiteCaracteres(item.nome_arquivo, maxLengths) }}
                    </div>
                  </template>
                  <span>{{ item.nome_arquivo }}</span>
                </v-tooltip>
                <div v-else>{{ item.nome_arquivo }}</div>
              </template>
            </td>
            <td class="text-left">
              <v-tooltip bottom v-if="item.url_download.length > maxLengths">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="limite-caracteres">
                    {{ limiteCaracteres(item.url_download, maxLengths) }}
                  </div>
                </template>
                <span>{{ item.url_download }}</span>
              </v-tooltip>
              <div v-else>{{ item.url_download }}</div>
            </td>
            <td class="text-left">
              {{ item.content_type }}
            </td>
            <td class="text-center">{{ item.obrigatorio ? "Sim" : "Não" }}</td>
            <td class="menu-tabela">
              <v-btn
                icon
                :disabled="removendoAnexo"
                v-if="item == itemSelecionado && possivelRemover"
                @click="removerAnexo(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div v-if="carregandoAnexos && !erroCarregarAnexos">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <p class="text-center mt-2 pb-2 grey--text">Carregando anexos...</p>
    </div>
    <div
      v-if="!todosAnexos?.length && !erroCarregarAnexos && !carregandoAnexos"
      class="d-flex justify-center mt-3"
    >
      <p class="anexos-sem-dados">Nenhum anexo encontrado...</p>
    </div>
    <v-alert
      v-if="erroCarregarAnexos && !carregandoAnexos"
      type="error"
      prominent
      text
    >
      <v-row align="center">
        <v-col class="grow">Não foi possível carregar os anexos.</v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="carregaAnexosEvento()">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  props: {
    evento: null,
    dialogAberta: false,
    anexos: null,
    multiplo: false,
    possivelRemover: true,
    maxLengths: () => 10
  },
  data: () => ({
    tabelaHeader: [
      {
        text: "Nome do Arquivo",
        value: "nome_arquivo",
        align: "left",
        sortable: false,
        width: "25%",
      },
      {
        text: "URL de Download",
        value: "url_download",
        sortable: false,
        width: "25%",
      },
      {
        text: "Tipo do Arquivo",
        value: "tipo_arquivo",
        align: "left",
        sortable: false,
        width: "20%",
      },
      {
        text: "Obrigatório",
        value: "obrigatorio",
        align: "right",
        sortable: false,
        width: "20%",
      },
      {
        text: "",
        value: "menu",
        align: "right",
        sortable: false,
        width: "10%",
      },
    ],
    itemSelecionado: false,
    removendoAnexo: false,
  }),
  created() {
    this.carregaAnexosEvento();
  },
  methods: {
    ...mapActions("evento", {
      getAnexosEvento: "getAnexosEvento",
      removerAnexoEvento: "removerAnexoEvento",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    selecionaItem(item) {
      this.itemSelecionado = item;
    },
    desselecionarItem() {
      this.itemSelecionado = false;
    },

    limiteCaracteres(text, maxLength) {
      // Function to limit the character length
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "..."; // Truncate the text if it exceeds the limit
      }
      return text;
    },
    async removerAnexo(anexoSelecionado) {
      this.removendoAnexo = true;
      await this.removerAnexoEvento(anexoSelecionado.id)
        .then((retorno) => {
          let index = this.todosAnexos.findIndex((anexo) => {
            return anexo.id == retorno.data.id;
          });
          this.todosAnexos.splice(index, 1);
          this.showSnackBar({
            text: "Anexo removido com sucesso!",
          });
          this.removendoAnexo = false;
        })
        .catch((error) => {
          console.error(error);
          this.showSnackBar({
            text: "Erro ao remover o anexo!",
          });
          this.removendoAnexo = false;
        });
    },
    async carregaAnexosEvento() {
      if (this.anexos != null) return;
      let params = {
        evento_id: this.evento.id,
        limite: 1000,
        template_email_id: this.evento?.template_email_id || null,
      };
      await this.getAnexosEvento(params);
    },
  },
  computed: {
    ...mapState("regua", ["regua"]),
    ...mapGetters("evento", {
      anexosEvento: "anexosEvento",
      carregandoAnexos: "carregandoAnexos",
      erroCarregarAnexos: "erroCarregarAnexos",
    }),
    todosAnexos() {
      if (this.anexos?.length > 0) {
        return this.anexos;
      } else return this.anexosEvento?.objects;
    },
    definirNomePorTipoRegua() {
      if (this.regua.tipo === "IMPLANTACAO") {
        return "Múltiplas Implantações";
      }
      return "Múltiplos Vencimentos";
    },
  },
  watch: {
    dialogAberta: {
      handler() {
        if (this.dialogAberta) {
          this.carregaAnexosEvento();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.menu-tabela {
  width: 40px !important;
  min-width: 40px;
  max-width: 40px;
  padding: 0px !important;
}

.anexos-sem-dados {
  font-weight: 700;
  font-size: 16px;
  color: #a6a6a6 !important;
}

.limite-caracteres {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
