<template>
  <v-text-field
    @click:append="true"
    filled
    dense
    rounded
    :solo="true"
    @focus="buscaEmFoco = true"
    @blur="buscaEmFoco = false"
    class="grey lighten-4 search-field mr-4"
    placeholder="Nome do template"
    hide-details=""
    ref="searchField"
    v-model="filtro.input"
    :class="filtersMenu ? 'rounded-b-0' : ''"
    @keydown.enter="removerFocoEprocurar()"
    loading="true"
  >
    <template v-slot:append>
      <slot name="chips"></slot>
      <div class="d-flex align-center justify-center">
        <v-menu
          @keydown.enter="$emit('search')"
          :close-on-content-click="false"
          v-model="filtersMenu"
          rounded="b-xl t-0"
          left
          offset-y
          nudge-right="4px"
          transition="slide-y-transition"
          class="elevation-1"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon class=""> <v-icon>mdi-tune</v-icon></v-btn>
          </template>
          <v-list class="pa-0">
            <v-card rounded="0" :max-width="filtersCardWidth" :min-width="filtersCardWidth">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="filtro.tipoRegua"
                    :items="tiposRegua()"
                    item-value="tipo"
                    item-text="tipo"
                    label="Tipo da régua"
                    :menu-props="{closeOnClick: true}"
                    clearable
                  >
                  >
                <template v-slot:selection="{item}">
                  {{ item.tipo | tipoRegua }}
                </template>
                <template v-slot:item="{item}">
                  {{ item.tipo | tipoRegua }}
                </template></v-select>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filtro.data"
                        label="Data de modificação"
                        readonly
                        v-on="on"
                        v-bind="attrs"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      @input="dataFormatada()"
                      v-model="data"
                      range
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="grey darken-1" @click="limparFiltros()"
                >Limpar filtros</v-btn
              >
              <v-btn color="primary" @click="procurar()">Procurar</v-btn>
            </v-card-actions>
          </v-card>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-slot:prepend-inner>
      <div class="d-flex align-center justify-center">
        <v-btn @click="$emit('search')" icon class="">
          <v-icon>mdi-magnify</v-icon></v-btn
        >
      </div>
    </template>
  </v-text-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["filtro"],
  model: {
    prop: "filtro",
    event: "input",
  },
  data: () => {
    return {
      filtersMenu: false,
      buscaEmFoco: false,
      filtersCardWidth: null,
      data: null,
    };
  },
  methods: {
    removerFocoEprocurar() {
      this.$refs.searchField.blur();
      this.$emit("search");
    },
    procurar() {
      this.$emit("search");
      this.filtersMenu = false;
    },
    limparFiltros() {
      this.$emit("input", {
        input: null,
        data: null,
        tipoRegua: null,
      });
      this.$emit("search");
      this.filtersMenu = false;
    },
    dataFormatada() {
      if (!this.data) return null;
      this.filtro.data = this.data.join(" ~ ");
      return this.data.join(" ~ ");
    },
  },
  computed: {
    ...mapGetters("reguas", ["tiposRegua"]),
  },
  watch: {
    filtersMenu(newValue) {
      if (newValue) {
        this.filtersCardWidth = this.$refs.searchField.$el.offsetWidth;
      }
    },
  },
};
</script>

<style lang="scss">
.templa-email-app-bar {
  .v-toolbar__content {
    .v-input--is-focused {
      background: white !important;
    }
  }
}
.search-field {
  max-width: 600px;
  .v-input__append-inner,
  .v-input__prepend-inner {
    margin: 0px !important;
  }
  .v-input__slot {
    padding: 0px 4px !important;
  }
  .v-menu__content {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
</style>