<template>
  <div
    class="pt-8 pb-8 d-flex justify-center"
    :class="{
      'pr-8 pl-8 mr-4 ml-4': $vuetify.breakpoint.smAndDown,
      'pr-16 pl-16 mr-8 ml-8': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <v-app-bar app class="templa-email-app-bar">
      <div class="text-h6 font-weight-bold ml-3">E-mails</div>
      <v-spacer></v-spacer>
      <SearchField @search="pesquisarTemplates" v-model="filtroTemplate">
        <template slot="chips">
          <div class="d-flex justify-center align-center">
            <v-chip
              outlined
              small
              close
              @click:close="removeFiltroAplicado('tipoRegua')"
              color="black"
              class="mr-2"
              v-if="filtrosAplicados.tipoRegua"
            >
              {{ filtrosAplicados.tipoRegua | tipoRegua }}
            </v-chip>
            <v-chip
              outlined
              small
              close
              @click:close="removeFiltroAplicado('data')"
              color="black"
              v-if="filtrosAplicados.data"
            >
              {{ filtrosAplicados.data }}
            </v-chip>
          </div>
        </template>
      </SearchField>
      <v-switch
        hide-details=""
        label="Templates arquivados"
        class="mr-4"
        v-model="mostrarArquivadas"
        @change="pesquisarTemplates"
        :disabled="carregandoEmails"
      ></v-switch>
    </v-app-bar>
    <div class="d-flex justify-center">
      <v-container class="d-flex justify-center flex-column align-center">
        <v-skeleton-loader
          v-if="carregandoEmails"
          class="mx-auto"
          width="1000"
          type="table-tbody"
        ></v-skeleton-loader>

        <v-data-table
          v-else-if="emailsFiltrados?.length > 0"
          :headers="cabecalhos"
          :items="emailsFiltrados"
          :items-per-page="-1"
          class="elevation-1 tabela"
          hide-default-footer
          v-infinite-scroll="carregarMais"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template>
                <tr v-if="loadingDuplicando" id="loadingRow">
                  <td>
                    <v-skeleton-loader
                      type="text"
                      width="40px"
                    ></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader
                      width="200px"
                      type="text"
                    ></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader
                      type="text"
                      width="75px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template>
                <v-hover
                  v-for="templateEmail in items"
                  v-slot="{ hover }"
                  :key="templateEmail.id"
                  :class="templateEmail.status"
                >
                  <tr
                    :key="templateEmail.id"
                    @click.stop="setSelectedTemplate(templateEmail)"
                  >
                    <td>#{{ templateEmail.id }}</td>
                    <td class="d-flex align-center justify-start">
                      <div class="d-flex flex-column align-start">
                        <span
                          class="text-body-1 font-weight-medium mr-2 text-template__nome"
                          >{{ limiteCaracteres(templateEmail.nome, 50) }}
                          <v-tooltip top v-if="templateEmail.assunto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon small v-on="on" v-bind="attrs"
                                >mdi-text-long</v-icon
                              >
                            </template>
                            {{ templateEmail.assunto }}
                          </v-tooltip>
                          <v-tooltip top v-if="templateEmail.anexos?.length">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-2"
                                small
                                v-on="on"
                                v-bind="attrs"
                                >mdi-paperclip</v-icon
                              >
                            </template>
                            {{ templateEmail.anexos.length }}
                            {{
                              templateEmail.anexos.length > 1
                                ? "anexos"
                                : "anexo"
                            }}
                          </v-tooltip>
                        </span>
                      </div>

                      <div v-if="templateEmail.eventos_vinculados?.length > 0">
                        <v-chip
                          small
                          outlined
                          color="primary"
                          @click.stop="
                            abrirDialogEventosVinculados(templateEmail)
                          "
                        >
                          {{ templateEmail.eventos_vinculados?.length }}
                          {{
                            templateEmail.eventos_vinculados?.length == 1
                              ? "evento"
                              : "eventos"
                          }}
                          {{
                            templateEmail.eventos_vinculados?.length == 1
                              ? "vinculado"
                              : "vinculados"
                          }}
                        </v-chip>
                      </div>
                      <div>
                        <v-chip
                          small
                          class="text-center white--text ml-2"
                          :color="templateEmail?.tipo_regua"
                        >
                          {{ templateEmail?.tipo_regua | tipoRegua }}
                        </v-chip>
                      </div>
                    </td>
                    <td class="text-right">
                      <span
                        class="text-caption grey--text text-atualizado font-weight-bold"
                        >{{
                          formatDateTimeToUTC3(templateEmail.atualizado_em)
                        }}</span
                      >
                    </td>
                    <td>
                      <div class="d-flex align-center justify-end">
                        <v-chip
                          v-if="statusCarregando == templateEmail.id"
                          small
                        >
                          <v-progress-circular
                            indeterminate
                            h
                            size="20"
                            width="2"
                            color="grey"
                            class="mx-5"
                          ></v-progress-circular>
                        </v-chip>
                        <v-chip
                          v-else
                          small
                          :color="corDoEstado[templateEmail.status]?.fundo"
                          :text-color="corDoEstado[templateEmail.status]?.texto"
                        >
                          {{ templateEmail.status | eventoStatus }}
                        </v-chip>
                        <div class="menu ml-2">
                          <v-menu
                            v-model="menusAtivo[templateEmail.id]"
                            class="menu"
                            offset-x
                            offset-y
                            dense
                            v-if="hover || menusAtivo[templateEmail.id]"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="show-on-hover"
                                icon
                                v-on="on"
                                v-bind="attrs"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-if="
                                  !['ARQUIVADO'].includes(templateEmail.status)
                                "
                                @click="verificaEditar(templateEmail)"
                              >
                                <v-list-item-title>
                                  <v-btn plain>Editar</v-btn>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="
                                  ['PRODUCAO'].includes(templateEmail.status)
                                "
                                @click="
                                  irParaCadastroEmail(templateEmail, true)
                                "
                              >
                                <v-list-item-title>
                                  <v-btn plain>Visualizar</v-btn>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="
                                  !['PRODUCAO', 'ARQUIVADO'].includes(
                                    templateEmail.status
                                  )
                                "
                                @click="
                                  publicarTemplate(
                                    templateEmail,
                                    'PRODUCAO',
                                    'publicado'
                                  )
                                "
                              >
                                <v-list-item-title>
                                  <v-btn plain>Publicar</v-btn>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="
                                  !['ARQUIVADO', 'PRODUCAO'].includes(
                                    templateEmail.status
                                  )
                                "
                                @click="abrirDialogAnexar(templateEmail)"
                              >
                                <v-list-item-title>
                                  <v-btn plain>Anexar</v-btn>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                @click="abrirDialogDuplicar(templateEmail)"
                              >
                                <v-list-item-title>
                                  <v-btn plain> Duplicar </v-btn>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="templateEmail.status === 'PRODUCAO'"
                                @click="despublicarTemplate(templateEmail)"
                              >
                                <v-list-item-title>
                                  <v-btn plain>Mover para rascunho</v-btn>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="
                                  templateEmail.status == 'ARQUIVADA' ||
                                  templateEmail.status !== 'ARQUIVADO'
                                "
                                @click="arquivarTemplate(templateEmail)"
                              >
                                <v-list-item-title>
                                  <v-btn plain> Arquivar </v-btn>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </div>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </tbody>
          </template>

          <template v-slot:footer>
            <div>
              <v-progress-linear
                indeterminate
                v-if="carregandoMaisEmails"
              ></v-progress-linear>
              <div v-else-if="page == total_pages"></div>
            </div>
          </template>
        </v-data-table>

        <v-row v-else class="mt-8">
          <v-col cols="12">
            <h2 class="grey--text text-center">
              Nenhum template de e-mail cadastrado
            </h2>
          </v-col>
        </v-row>
        <div transition="scale-transition" class="" v-if="selectedTemplate">
          <v-dialog
            scrollable
            v-model="previewDialog"
            max-width="700"
            min-width="700"
            :value="selectedTemplate || false"
          >
            <v-card min-width="700">
              <v-card-title
                class="primary py-3 white--text d-flex align-center justify-center"
                >{{ selectedTemplate.nome }}
                <v-spacer></v-spacer>
                <v-btn icon @click="previewDialog = false"
                  ><v-icon color="white">mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-card-text class="dialog-preview" tyle="height: 80vh">
                <div s>
                  <div v-html="templateComExemplos"></div>
                </div>
                <div>
                  <TabelaAnexos
                    :maxLengths="30"
                    :possivelRemover="false"
                    :anexos="selectedTemplate.anexos"
                  ></TabelaAnexos>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </v-container>
    </div>

    <FloatingBtn @click="irParaCadastroEmail()" />

    <ModalCrudEmail
      @close="random = Math.random()"
      :key="random"
      @atualizaTemplate="atualizaTemplate($event)"
      ref="modalCrudEmail"
    />
    <DialogAguardandoDecisao
      ref="dialogAguardandoDecisao"
    ></DialogAguardandoDecisao>

    <v-dialog width="500px" v-model="dialogEventosVinculados">
      <v-card>
        <v-card-title
          >Eventos vinculados
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogEventosVinculados = false"
            ><v-icon color="black">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-alert type="warning" v-if="mostrarAlertaEventosVinculados">
            Para modificar um template em produção, é necessário remover todos
            os vínculos aos eventos.
          </v-alert>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Evento</th>
                  <th class="text-right">Régua id</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    evento, index
                  ) in emailSelecionado?.eventos_vinculados"
                  :key="index"
                >
                  <td @click="irParaEvento(evento)">
                    <a>
                      <span>#{{ evento.id }}</span>
                      <span v-if="evento.nome"> - {{ evento.nome }}</span>
                    </a>
                  </td>
                  <td @click="irParaRegua(evento)" class="text-right">
                    <a> #{{ evento.regua_id }} </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="d-flex mt-2" v-if="mostrarAlertaEventosVinculados">
            <v-spacer></v-spacer>
            <v-btn
              @click="verificaEditar(emailSelecionado, { readonly: true })"
              color="primary"
              >Visualizar TEMPLATE</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DialogAnexar ref="DialogAnexar" />
    <DialogDuplicarTemplete
      ref="DialogDuplicarTemplete"
      @duplicarTemplate="duplicarTemplate($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalCrudEmail from "../components/modalCrudEmail.vue";
import DialogAguardandoDecisao from "../components/DialogAguardandoDecisao.vue";
import SearchField from "../components/searchField.vue";
import DialogDuplicarTemplete from "../components/DialogDuplicarTemplete.vue";
import { mapMutations } from "vuex/dist/vuex.common.js";
import DialogAnexar from "../components/DialogAnexar.vue";
import { formatDateTimeToUTC3 } from "../../../utils/date";
import TabelaAnexos from "../components/TabelaAnexos.vue";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";

export default {
  components: {
    ModalCrudEmail,
    SearchField,
    DialogAguardandoDecisao,
    DialogDuplicarTemplete,
    DialogAnexar,
    TabelaAnexos,
    FloatingBtn,
  },
  async created() {
    this.pegarParametrosDaQuery();
    this.pegarVariaveis({ empresa_id: this.empresaSelecionada.id });
    await this.pesquisarTemplates();
    if (this.$route.query.template) {
      await this.abrirTemplate(this.$route.query.template);
    }
  },
  data: () => {
    return {
      formatDateTimeToUTC3,
      menusAtivo: {},
      mostrarArquivadas: false,
      carregandoEmails: false,
      carregandoMaisEmails: false,
      statusCarregando: null,
      selectedTemplate: null,
      loadingDuplicando: false,
      dialogEventosVinculados: false,
      mostrarAlertaEventosVinculados: false,
      random: null,
      emailSelecionado: null,
      previewDialog: false,
      filtroTemplate: {
        input: null,
        data: null,
        tipoRegua: null,
      },
      filtrosAplicados: {
        input: null,
        data: null,
        tipoRegua: null,
      },
      cabecalhos: [
        { text: "", value: "id", width: "2%", sortable: false },
        { text: "Nome", value: "nome", align: "left", sortable: false },
        {
          text: "Data de modificação",
          value: "atualizado_em",
          align: "right",
          sortable: false,
        },
        { text: "", value: "status", align: "end", width: "2%" },
      ],
      corDoEstado: {
        PRODUCAO: {
          fundo: "#4CAF50",
          texto: "#FFFFFF",
        },
        RASCUNHO: {
          fundo: "#2196F3",
          texto: "#FFFFFF",
        },
        ARQUIVADA: {
          fundo: "#999999",
          texto: "#FFFFFF",
        },
        ARQUIVADO: {
          fundo: "#999999",
          texto: "#FFFFFF",
        },
      },
    };
  },
  methods: {
    ...mapMutations("emails", ["setEmails"]),
    ...mapActions("snackbar", ["showSnackBar"]),
    ...mapActions("emails", [
      "getEmails",
      "getMaisEmails",
      "getEmail",
      "salvar",
      "publicar",
      "despublicar",
      "arquivarEmail",
      "restaurar",
      "pegarAnexo",
    ]),
    ...mapActions("emails", {
      salvarEmail: "salvar",
    }),
    ...mapActions("variavelRegua", { pegarVariaveis: "pegar" }),
    getStatus() {
      if (this.mostrarArquivadas) {
        return "ARQUIVADO,RASCUNHO,PRODUCAO";
      } else return "RASCUNHO,PRODUCAO";
    },
    async pesquisarTemplates() {
      for (let param in this.filtroTemplate) {
        if (this.filtroTemplate[param] === null) {
          delete this.filtroTemplate[param];
        }
      }
      const template = this.$route.query.template
        ? { template: this.$route.query.template }
        : null;
      this.$router
        .replace({
          query: {
            ...this.filtroTemplate,
            status: this.getStatus(),
            ...template,
          },
        })
        .catch(() => {});
      const params = {
        busca: this.filtroTemplate.input,
        tipo_regua: this.filtroTemplate.tipoRegua,
        data: this.filtroTemplate.data,
        status: this.getStatus(),
        incluir: "eventos_vinculados,anexos",
        limite: 40,
      };
      await this.carregaEmails(params);
      this.filtrosAplicados = {
        tipoRegua: this.filtroTemplate.tipoRegua,
        data: this.filtroTemplate.data,
      };
    },
    async carregarMais() {
      if (this.carregandoMaisEmails || this.page >= this.total_pages) return;
      this.carregandoMaisEmails = true;
      try {
        const params = {
          busca: this.filtroTemplate.input,
          tipo_regua: this.filtroTemplate.tipoRegua,
          data: this.filtroTemplate.data,
          status: this.getStatus(),
          incluir: "eventos_vinculados",
          limite: 40,
        };
        await this.getMaisEmails(params);
      } catch (error) {
        console.error(error);
      } finally {
        this.$nextTick(() => {
          this.carregandoMaisEmails = false;
        });
      }
    },

    atualizaTemplate(template) {
      const index = this.emails.findIndex((item) => item.id == template.id);
      if (index >= 0) {
        Object.assign(this.emails[index], { ...template });
      } else this.emails.unshift(template);
    },
    async verificaEditar(templateEmail, options = { readonly: false }) {
      if (options.readonly) {
        this.irParaCadastroEmail(templateEmail, true);
      }
      if (
        templateEmail.status == "PRODUCAO" &&
        templateEmail.eventos_vinculados?.length > 0
      ) {
        this.emailSelecionado = templateEmail;
        this.mostrarDialogDesvincularEventos();
        return;
      } else if (templateEmail.status == "PRODUCAO") {
        const decisao = await this.abrirDialogEditarTemplateEmProducao();
        if (!decisao) return;
        await this.despublicarTemplate(templateEmail);
      }
      this.irParaCadastroEmail(templateEmail);
    },
    abrirDialogEventosVinculados(templateEmail) {
      this.dialogEventosVinculados = true;
      this.emailSelecionado = templateEmail;
    },
    removeFiltroAplicado(filtro) {
      this.filtrosAplicados[filtro] = null;
      this.filtroTemplate[filtro] = null;
      this.pesquisarTemplates();
    },
    async despublicarTemplate(templateEmail) {
      if (
        templateEmail.status == "PRODUCAO" &&
        templateEmail.eventos_vinculados?.length > 0
      ) {
        this.emailSelecionado = templateEmail;
        this.mostrarDialogDesvincularEventos();
        return;
      }
      try {
        this.statusCarregando = templateEmail.id;
        const response = await this.despublicar(templateEmail);
        Object.assign(templateEmail, response);
        this.showSnackBar({
          text: "Template movido para rascunho com sucesso",
        });
      } catch (error) {
        this.showSnackBar({ text: "Falha ao despublicar o template!" });
        throw new Error(error);
      } finally {
        this.statusCarregando = null;
      }
    },
    limiteCaracteres(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "..."; // Truncate the text if it exceeds the limit
      }
      return text;
    },
    abrirDialogDuplicar(templateEmail) {
      this.$refs.DialogDuplicarTemplete.open(templateEmail);
    },
    abrirDialogAnexar(templateEmail) {
      this.$refs.DialogAnexar.open(templateEmail);
    },
    async duplicarTemplate(templateEmail) {
      this.loadingDuplicando = true;
      await this.$nextTick(() => {});
      const loadingRow = document.querySelector("#loadingRow");
      this.$vuetify.goTo(loadingRow, { offset: 100, duration: 150 });
      try {
        const payload = structuredClone(templateEmail);
        delete payload.id;
        delete payload.atualizado_em;
        delete payload.atualizado_por;
        delete payload.criado_em;
        delete payload.criado_por;
        delete payload.eventos_vinculados;
        delete payload.anexos;

        const response = await this.salvar({ payload, id: null });
        this.$refs.DialogDuplicarTemplete.close();
        setTimeout(() => {
          this.loadingDuplicando = false;
          this.emails.unshift(response);
          this.showSnackBar({ text: "Template duplicado com sucesso" });
        }, 400);
      } catch (error) {
        const erro_nome = error?.response?.data?.erros.find((item) => {
          return item.tipo_erro === "NOME";
        });

        if (erro_nome) {
          this.$refs.DialogDuplicarTemplete.setErrorMessages(
            "Nome ja existente!"
          );
          this.showSnackBar({
            text: "Falha ao duplicar o template, escolha outro nome",
          });
        } else {
          this.$refs.DialogDuplicarTemplete.setErrorMessages(null);
          this.showSnackBar({
            text: "Falha ao duplicar o template",
          });
        }

        this.loadingDuplicando = false;
        console.error(error);
      } finally {
        this.$refs.DialogDuplicarTemplete.loading = false;
      }
    },
    async abrirDialogEditarTemplateEmProducao() {
      return await this.$refs.dialogAguardandoDecisao.open();
    },
    async mostrarDialogDesvincularEventos() {
      this.dialogEventosVinculados = true;
      this.mostrarAlertaEventosVinculados = true;
    },
    irParaCadastroEmail(templateEmail, readonly = false) {
      this.$refs.modalCrudEmail.open(templateEmail, readonly);
    },
    async setSelectedTemplate(item, context, event) {
      this.selectedTemplate = item;
      this.previewDialog = true;
    },
    async abrirTemplate(templateId) {
      if (templateId == "novo") {
        this.$refs.modalCrudEmail.open();
      } else {
        try {
          const template = await this.pegaTemplate(templateId);
          this.irParaCadastroEmail(template);
        } catch (error) {
          let query = Object.assign({}, this.$route.query);
          delete query.template;
          this.$router.replace({ query });
        }
      }
    },
    async pegaTemplate(templateId) {
      const template = this.emails.find(
        (templateEmail) => templateEmail.id == templateId
      );
      if (template) return template;
      try {
        return await this.getEmail(templateId);
      } catch (error) {
        throw new Error(e);
      }
    },
    async arquivarTemplate(templateEmail, status, acao) {
      if (
        templateEmail.status == "PRODUCAO" &&
        templateEmail.eventos_vinculados?.length > 0
      ) {
        this.emailSelecionado = templateEmail;
        this.mostrarDialogDesvincularEventos();
        return;
      }
      this.statusCarregando = templateEmail.id;
      try {
        const resposta = await this.arquivarEmail(templateEmail.id);
        templateEmail.status = resposta.status;
        this.showSnackBar({ text: `Template arquivado com sucesso!` });
      } catch (e) {
        console.error(e);
        this.showSnackBar({ text: `Falha, template não arquivado!` });
      } finally {
        this.statusCarregando = null;
      }
    },
    async restaurarTemplate(templateEmail, status, acao) {
      this.statusCarregando = templateEmail.id;
      try {
        const resposta = await this.restaurar({
          id: templateEmail.id,
          status: "RASCUNHO",
        });
        templateEmail.status = resposta.status;
        this.showSnackBar({ text: `Template restaurado com sucesso!` });
      } catch (e) {
        console.error(e);
        this.showSnackBar({ text: `Falha, template não restaurado!` });
      } finally {
        this.statusCarregando = null;
      }
    },
    async publicarTemplate(template) {
      try {
        this.statusCarregando = template.id;
        const response = await this.publicar(template);
        Object.assign(template, response);
        this.showSnackBar({ text: "Template publicado com sucesso" });
      } catch (error) {
        const { data } = error.response;
        this.showSnackBar({
          text: `Falha ao publicar, ${
            data?.erros?.length > 1
              ? data?.erros?.length + " erros encontrados"
              : data?.erros?.length + " erro encontrado"
          } `,
        });
      } finally {
        this.statusCarregando = null;
      }
    },
    async carregaEmails(params) {
      try {
        this.carregandoEmails = true;
        await this.getEmails({
          empresa_id: this.empresaSelecionada.id,
          ...params,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.carregandoEmails = false;
      }
    },
    pegarParametrosDaQuery() {
      this.filtroTemplate = {
        data: this.$route.query.data || null,
        input: this.$route.query.input || null,
        tipoRegua: this.$route.query.tipoRegua || null,
      };
      this.mostrarArquivadas = this.$route.query.status?.includes("ARQUIVADO");
    },
    async irParaEvento(evento) {
      const url = this.$router.resolve({
        name: "eventos",
        query: { evento_id: evento.id },
        params: { regua_id: evento.regua_id },
      }).href;
      window.open(url, "_blank");
    },
    irParaRegua(evento) {
      const url = this.$router.resolve({
        name: "eventos",
        params: { regua_id: evento.regua_id },
      }).href;
      window.open(url, "_blank");
    },
  },
  computed: {
    ...mapGetters("emails", ["page", "total_pages"]),
    ...mapGetters("emails", {
      emailsFromStore: "emails",
    }),
    ...mapGetters("empresa", ["empresaSelecionada"]),
    ...mapGetters("variavelRegua", ["variaveis"]),
    emails: {
      get: function () {
        return this.emailsFromStore;
      },
      set: function (value) {
        this.setEmails(value);
      },
    },
    templateComExemplos() {
      let conteudo = this.selectedTemplate.conteudo;
      const regex = /{{(.*?)}}/g;
      let match;
      const variaveisParaSubistituir = [];
      while ((match = regex.exec(conteudo)) !== null) {
        if (match) {
          const exemplo = this.variaveis.find((variavel) => {
            return variavel.nome.includes(match[1].replace(/\s+/g, ""));
          });
          if (exemplo) {
            variaveisParaSubistituir.push({
              variavel: match[0],
              exemplo: exemplo.exemplo,
            });
          }
        }
      }
      variaveisParaSubistituir.forEach((variavel) => {
        conteudo = conteudo.replace(variavel.variavel, variavel.exemplo);
      });
      return conteudo;
    },
    emailsFiltrados() {
      let ordem = {
        PRODUCAO: 1,
        RASCUNHO: 2,
        ARQUIVADA: 3,
      };

      return this.emails
        ?.filter((item) => {
          if (!this.mostrarArquivadas && item.status == "ARQUIVADO")
            return false;
          return true;
        })
        .sort((a, b) => {
          const dateA = new Date(a.atualizado_em);
          const dateB = new Date(b.atualizado_em);
          return dateB - dateA;
        });
    },
  },
  watch: {
    filtersMenu(newValue) {
      if (newValue) {
        this.filtersCardWidth = this.$refs.searchField.$el.offsetWidth;
      }
    },
    empresaSelecionada: {
      handler(newValue) {
        this.pesquisarTemplates();
        this.pegarVariaveis({ empresa_id: this.empresaSelecionada.id });
        this.filtrosAplicados = {};
        this.filtroTemplate = {};
      },
    },
    dialogEventosVinculados(newValue) {
      if (!newValue) this.mostrarAlertaEventosVinculados = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 30px;
  height: 36px;
}
.tabela {
  min-width: 450px;
  width: 1000px;
}
.ARQUIVADO {
  color: lightgrey;
}
.frame-container {
  height: 80vh;
}
.dialog-preview {
  padding: 0px !important;
}
.text-atualizado {
  font-size: 10px !important;
}
.text-template__nome {
  word-break: keep-all;
  text-overflow: ellipsis;
  max-width: 100%;
}
.EMAIL {
  background-color: #ff9800 !important;
  color: white !important;
}
.FAST_SMS {
  background-color: #075e54 !important;
  color: white !important;
}
.LIGACAO_CELULAR {
  background-color: #2196f3 !important;
  color: white !important;
}
.PUSH {
  background-color: #388e3c !important;
  color: white !important;
}
.SMS {
  background-color: #3f51b5 !important;
  color: white !important;
}
.COMANDO {
  background-color: #555 !important;
  color: white !important;
}
.WHATSAPP {
  background-color: #1b5e20 !important;
  color: white !important;
}
.PENDENTE {
  background-color: #2196f3 !important;
  color: white !important;
}
.ENVIADO {
  background-color: #4caf50 !important;
  color: white !important;
}
.NAO_ENVIADO {
  background-color: #f44336 !important;
  color: white !important;
}
.ERRO {
  background-color: #f44336 !important;
  color: white !important;
}
.ATENDIDA {
  background-color: #4caf50 !important;
  color: white !important;
}
.NAO_ATENDIDA {
  background-color: #f44336 !important;
  color: white !important;
}
.RECEBIDO {
  background-color: #4caf50 !important;
  color: white !important;
}
.ABRIU {
  background-color: #4caf50 !important;
  color: white !important;
}
.CLICOU {
  background-color: #4caf50 !important;
  color: white !important;
}
.VISUALIZADO {
  background-color: #4caf50 !important;
  color: white !important;
}
.IMPLANTACAO {
  background-color: #ffc107 !important;
  color: white !important;
}

.VENCIMENTO_BOLETO {
  background-color: teal !important;
  color: white !important;
}

.VENCIMENTO_DEBITO {
  background-color: teal !important;
  color: white !important;
}

.TOLERANCIA_BOLETO {
  background-color: purple !important;
  color: white !important;
}

.TOLERANCIA_DEBITO {
  background-color: purple !important;
  color: white !important;
}

.INICIO_CONTRATO {
  background-color: blue !important;
  color: white !important;
}

.TERMINO_CONTRATO {
  background-color: blue !important;
  color: white !important;
}

.CONTRATO_CANCELADO {
  background-color: green !important;
  color: white !important;
}

.CONTRATO_FINALIZADO {
  background-color: green !important;
  color: white !important;
}

.BOLETO_SOLICITADO {
  background-color: brown !important;
  color: white !important;
}

.PARCELA_BOLETO_CRIADA {
  background-color: grey !important;
  color: white !important;
}

.PARCELA_DEBITO_CRIADA {
  background-color: grey !important;
  color: white !important;
}

.PAGAMENTO_BOLETO_RECEBIDO {
  background-color: orange !important;
  color: white !important;
}

.PAGAMENTO_DEBITO_RECEBIDO {
  background-color: orange !important;
  color: white !important;
}

.ANIVERSARIO_DO_CLIENTE {
  background-color: indigo !important;
  color: white !important;
}

.MOVIMENTACAO {
  background-color: black !important;
  color: white !important;
}
</style>
