<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="370">
      <v-card>
        <v-card-title class="text-h5">
          Deseja editar este template?
        </v-card-title>
        <v-card-text
          >Ao editar esse template, seu status será alterado para rascunho.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="decision = false">
            Voltar
          </v-btn>
          <v-btn color="primary" @click="decision = true">
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    data () {
      return {
        dialog: false,
        decision: null
      }
    },
    methods: {
      async open(){
        this.dialog = true
        return new Promise((resolve) => {
          const interval = setInterval(() => {
            if (this.decision !== null) {
              resolve(this.decision)
              this.dialog = false
              this.decision = null
              clearInterval(interval)
            }
          }, 100)
        })
      }
    }
  }
</script>

<style>
</style>