<template v-if="dialog">
  <div>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> Duplicar template </v-card-title>
        <v-card-subtitle class="mt-1">
          Escolha um nome diferente para seu novo template
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            :error-messages="errorMessage"
            outlined
            v-model="email.nome"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="primary darken-1"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            :loading="loading"
            color="green white--text primary"
            @click="duplicar()"
          >
            Duplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warningDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5 text-error">
          Atenção <v-icon small>mdi-warning</v-icon>
        </v-card-title>
        <v-card-subtitle class="mt-1">
          O template original possui anexos. Caso você deseje que o template
          duplicado possua os mesmos anexos, você deve adicioná-los manualmente.
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="primary darken-1"
            text
            @click="warningDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            :loading="loading"
            color="green white--text primary"
            @click="confirmar()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      decision: null,
      email: {},
      errorMessage: null,
      loading: false,
      warningDialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.warningDialog = false;
    },
    open(email) {
      this.dialog = true;
      this.errorMessage = null;
      this.email = structuredClone(email);
    },
    duplicar() {
      if (this.email.anexos && this.email.anexos.length) {
        this.dialog = false;
        this.warningDialog = true;
      } else {
        this.confirmar();
      }
    },
    confirmar() {
      this.dialog = true;
      this.loading = true;
      this.warningDialog = false;
      this.$emit("duplicarTemplate", this.email);
    },
    setErrorMessages(message) {
      this.errorMessage = message;
    },
  },
};
</script>

<style>
</style>